<!-- <app-header></app-header> -->
<router-outlet></router-outlet>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div id="attractions" class="nearby-places-section">
  <h2 class="section-heading">Nearby Places</h2>
  <div class="unique-cards-container">
    <div class="card unique-card">
      <img src="assets/images/Lingaraja Temple.jpg" class="card-img-top" alt="Lingaraja Temple">
      <div class="card-body">
        <p class="card-text">Lingaraja Temple</p>
        <p>Distance from Hotel Orange to Lingaraja Temple, Bhubaneswar, Odisha: <strong> 4.5 km (Time: 13 mins)</strong> </p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Nandankanan Zoological Park.jpg" class="card-img-top" alt="Nandankanan Zoological Park">
      <div class="card-body">
        <p class="card-text">Nandankanan Zoological Park</p>
        <p>Distance from Hotel Orange to Nandankanan Zoological Park, Bhubaneswar, Odisha: <strong> 18.6 km (Time: 42 mins) </strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Udayagiri Caves.jpg" class="card-img-top" alt="Udayagiri Caves">
      <div class="card-body">
        <p class="card-text">Udayagiri Caves</p>
        <p>Distance from Hotel Orange to Udayagiri Caves, Bhubaneswar, Odisha: <strong> 11.3 km (Time: 26 mins) </strong> </p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Dhauli Giri Hills.jpg" class="card-img-top" alt="Dhauli Giri Hills">
      <div class="card-body">
        <p class="card-text">Dhauli Giri Hills</p>
        <p>Distance from Hotel Orange to Dhauli Giri Hills, Bhubaneswar, Odisha: <strong> 8.9 km (Time: 20 mins) </strong> </p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Sri Ram Temple.jpg" class="card-img-top" alt="Sri Ram Temple">
      <div class="card-body">
        <p class="card-text">Sri Ram Temple</p>
        <p>Distance from Hotel Orange to Sri Ram Temple, Bhubaneswar, Odisha: <strong> 3.8 km (Time: 11 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/ISKCON Temple.jpg" class="card-img-top" alt="ISKCON Temple">
      <div class="card-body">
        <p class="card-text">ISKCON Temple</p>
        <p>Distance from Hotel Orange to ISKCON Temple, Bhubaneswar, Odisha: <strong> 7.2 km (Time: 19 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Odisha State Museum.webp" class="card-img-top" alt="Odisha State Museum">
      <div class="card-body">
        <p class="card-text">Odisha State Museum</p>
        <p>Distance from Hotel Orange to Odisha State Museum, Bhubaneswar, Odisha: <strong> 1.5 km (Time: 5 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Ocean World Water Park.jpg" class="card-img-top" alt="Ocean World Water Park">
      <div class="card-body">
        <p class="card-text">Ocean World Water Park</p>
        <p>Distance from Hotel Orange to Ocean World Water Park, Bhubaneswar, Odisha: <strong> 18.5 km (Time: 44 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Jayadev Vatika.jpg" class="card-img-top" alt="Jayadev Vatika">
      <div class="card-body">
        <p class="card-text">Jayadev Vatika</p>
        <p>Distance from Hotel Orange to Jayadev Vatika, Bhubaneswar, Odisha: <strong> 11.4 km (Time: 27 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Kalinga Stadium.webp" class="card-img-top" alt="Kalinga Stadium">
      <div class="card-body">
        <p class="card-text">Kalinga Stadium</p>
        <p>Distance from Hotel Orange to Kalinga Stadium, Bhubaneswar, Odisha: <strong> 6.4 km (Time: 17 mins)</strong></p>
      </div>
    </div>
    <!-- Add 5 more cards -->
    <div class="card unique-card">
      <img src="assets/images/Pathani Samanta Planetarium.jpg" class="card-img-top" alt="New Place 1">
      <div class="card-body">
        <p class="card-text">Pathani Samanta Planetarium</p>
        <p>Distance from Hotel Orange to Pathani Samanta Planetarium, Bhubaneswar, Odisha: <strong> 6.2 km (Time: 16 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Biju Patnaik International Airport.jpg" class="card-img-top" alt="New Place 2">
      <div class="card-body">
        <p class="card-text">Biju Patnaik International Airport</p>
        <p>Distance from Hotel Orange to Biju Patnaik International Airport, Bhubaneswar, Odisha: <strong> 4.8 km (Time: 13 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Railway Station.jpg" class="card-img-top" alt="New Place 3">
      <div class="card-body">
        <p class="card-text">Railway Station</p>
        <p>Distance from Hotel Orange to Railway Station, Bhubaneswar, Odisha: <strong> 3.2 km (Time: 11 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Ekamra Kanan Botanical Gardens.jpg" class="card-img-top" alt="New Place 4">
      <div class="card-body">
        <p class="card-text">Ekamra Kanan Botanical Gardens</p>
        <p>Distance from Hotel Orange to Ekamra Kanan Botanical Gardens, Bhubaneswar, Odisha: <strong> 8.8 km (Time: 25 mins)</strong></p>
      </div>
    </div>
    <div class="card unique-card">
      <img src="assets/images/Chandaka Reserve Forest.jpg" class="card-img-top" alt="New Place 5">
      <div class="card-body">
        <p class="card-text">Chandaka Reserve Forest</p>
        <p>Distance from Hotel Orange to Chandaka Reserve Forest, Bhubaneswar, Odisha: <strong> 12.1 km (Time: 27 mins)</strong></p>
      </div>
    </div>
  </div>
</div>




<div class="gallery-container">
  <!-- Dynamic property images carousel in mobile view -->
  <div class="gallery-images-section">
    <h2>Our Gallery</h2>

    <!-- Carousel view for mobile devices -->
    <ngx-slick-carousel 
      class="gallery-carousel"
      *ngIf="isMobileView"
      [config]="slideConfig">
      <div ngxSlickItem *ngFor="let image of property?.imageList" class="gallery-image-card">
        <img [src]="image.url" alt="Property Image">
      </div>
    </ngx-slick-carousel>

    <!-- Grid view for larger screens -->
    <div class="gallery-image-grid" *ngIf="!isMobileView">
      <div class="gallery-image-card" *ngFor="let image of property?.imageList">
        <img [src]="image.url" alt="Property Image">
      </div>
    </div>
  </div>
</div>








<div class="container my-5">
  <div class="row" id="testimonials">
    <!-- First Column -->
    <div class="col-md-4 mb-4 d-flex flex-column justify-content-center align-items-start">
      <h2 class="headline font-weight-bold mb-3">Real Stays, Real Talk</h2>
      <p class="intro-text mb-4 text-muted">Here's what our guests are saying...</p>
      <a href="" class="btn btn-primary btn-lg writereview">
      <div style="padding-left: 10px;padding-right: 10px; color: white;">
        Write a Review
      </div>
    </a>
    </div>

    <!-- Second Column (Carousel for Desktop) -->
    <!-- Second Column (Carousel for Desktop) -->
<div class="col-md-8 mb-4 d-md-block">
  <div class="card shadow-sm border-0 h-100">
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner c-review-carousel" style="display: block;">
        <div
          class="carousel-item"
          data-interval="10000"
          *ngFor="let item of googleReviews; let i = index"
          [ngClass]="{ 'active': i === 0 }"
        >
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <div class="c-review p-3">
                <div style="text-align: center;">
                  <div>
                    <img
                      src="assets/images/test.png"
                      alt="{{ item.reviewerName }}"
                      class="rounded-circle img-fluid shadow-lg mb-3"
                      style="width: 80px; height: 80px;"
                    />
                  </div>
                  <div>
                    <h5 class="font-weight-bold mb-1">{{ item.reviewerName }}</h5>
                  </div>
                  <div>
                    <p class="small text-muted mb-2 c-review-text">{{ item.reviewText }}</p>
                  </div>
                  <div class="star-ratings text-warning">
                    {{ '★'.repeat(item.rating) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Carousel Controls Positioned Outside -->
    <div class="carousel-controls">
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>





  </div>
</div>






  <div id="location" style="margin-bottom: 2px;">
  <div style="position: relative; width: 100%; max-width: 100%; height: 600px;">
    <!-- The iframe -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d119769.91769050002!2d85.76560721273907!3d20.266716145561688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3a19a711e16c9a03%3A0x17c6c2210adec53!2s3rd%20and%204th%20Floor%2C%20City%20Heart%20Commercial%20Complex%2C%20Plot%20No-285%2C%20Budheswari%20Colony%2C%20Laxmisagar%2C%20Bhubaneswar%2C%20Odisha%20751006!3m2!1d20.266735399999998!2d85.8480089!5e0!3m2!1sen!2sin!4v1729061829502!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         <div   class="card" style="position: absolute; top: 50px; text-align: center; left: 50px; background-color: white; border-bottom-left-radius: 61px;box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-top-right-radius: 59px;
    padding: 50px;">
        <h3>Make Unforgettable Memories</h3>
        <p>We're here to assist you with any questions, requests, or bookings.<br> Please don't hesitate to reach out to us.</p>
        <button class="book-btn" (click)="navigate()">Book Your Stay</button>
        <p class="contact-footer">
          <i class="fa fa-phone"></i> {{property?.mobile}}
        </p>
        <p class="contact-footer">
          <i class="fa fa-envelope"></i> {{property?.email}}
        </p>

      </div>

  </div>
</div>
<!-- Dialogflow Messenger Integration -->

<df-messenger project-id="impactful-name-306810" agent-id="8bb6c52c-8667-4a24-a789-4cd78b154c96" intent="WEBSITE" storage-option="none" language-code="en" max-query-length="-1"   >
  <df-messenger-chat-bubble  id="chatbot" chat-subtitle=""></df-messenger-chat-bubble>
</df-messenger>
<a [href]="getWhatsappShareUrl()" class="float" target="_blank" >
  <i class="fa fa-whatsapp my-float"></i>
</a>

<app-footer></app-footer>

